import {Menu, MenuItem, Sidebar, SubMenu, useProSidebar} from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import ReceiptIcon from "@mui/icons-material/Receipt";
import PasswordIcon from "@mui/icons-material/Password";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BalanceIcon from '@mui/icons-material/Balance';
import { Outlet, Link } from "react-router-dom";
import {Button, withAuthenticator} from "@aws-amplify/ui-react";
import {Amplify} from "aws-amplify";
import awsconfig from "../aws-exports";

Amplify.configure(awsconfig);
function Root( {signOut} ) {
    const { collapseSidebar, toggleSidebar, collapsed, toggled, broken, rtl } =
        useProSidebar();

    return (
        <div id="app" style={({height: "100vh"}, {display: "flex"})}>
            <Sidebar
                backgroundColor="rgb(32, 178, 170)"
                style={{height: "100vh"}}>
                <Menu>
                    <MenuItem
                        icon={<MenuOutlinedIcon/>}
                        onClick={() => {
                            collapseSidebar();
                        }}
                        style={{textAlign: "center"}}
                    >
                        {" "}
                        <h2>Hindsight</h2>
                    </MenuItem>
                    <MenuItem
                        icon={<DashboardIcon/>}
                        component={<Link to="/dashboard" />}>Dashboard
                    </MenuItem>
                    <SubMenu icon={<TrendingUpIcon /> } label="Algorithms">
                        <MenuItem
                            icon={<BalanceIcon />}
                            component={<Link to="/balancedAlgorithm" />}>Balanced Algorithm</MenuItem>
                    </SubMenu>
                    <SubMenu icon={<ManageAccountsIcon />} label="Account">
                        <MenuItem
                            icon={<ReceiptIcon />}
                            component={<Link to="/billing" />}>Billing</MenuItem>
                        <MenuItem
                            icon={<PasswordIcon />}
                            component={<Link to="/password" />}>Password</MenuItem>
                    </SubMenu>
                    <MenuItem
                        icon={<HelpOutlineOutlinedIcon/>}
                        component={<Link to="/faq" />}>FAQ</MenuItem>
                    <div><Button onClick={signOut}>Sign Out</Button></div>
                </Menu>
            </Sidebar>
            <main>
                <div id="detail"><Outlet /></div>
            </main>
        </div>
    );
}

export default withAuthenticator(Root, { hideSignUp: true });